<template>
  <div class="income">
    <div class="top">
      <el-image class="elImgs" :src="nft.imageUrl" fit="cover"></el-image>
      <div class="conte">
        <div class="title">
          <span class="label">作品:</span>
          <span class="value">{{ nft.title }}</span>
        </div>
        <div class="item">
          <span class="label">作品类型:</span>
          <span class="value">{{ nft.articleType }}</span>
        </div>
        <div class="item">
          <span class="label">发布时间：</span>
          <span class="value">{{ nft.createTime }}</span>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="left">
        <span>收益合计：</span>
        <div class="nums">
          <div class="item">
            <span class="money">{{ moneyObj.ethAmout }}</span>
            ETH
            <img
              style="width: 20px; margin-left: 8px"
              src="@/assets/images/ethBig.png"
              alt=""
            />
          </div>
          <div class="item">
            <span class="money">{{ moneyObj.daicAmount }}</span>
            DAIC
            <img
              style="width: 20px; margin-left: 8px"
              src="@/components/Header/images/btc.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/xuxie.png"
              alt=""
            />
            <span>续写收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.renewIncomeAmount }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/liwu.png"
              alt=""
            />
            <span>礼品收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.giftEthAmout }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.giftDaicAmount }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/yuedu.png"
              alt=""
            />
            <span>阅读收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.readEthAmount }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.readDaicAmount }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/qita.png"
              alt=""
            />
            <span>其他收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.otherEthAmout }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.otherDaicAmount }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hader">NFT关联收入记录</div>
    <div
      class="content"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      element-loading-text="拼命加载中"
    >
      <div class="table">
        <div
          class="table-column"
          v-for="item in list"
          :key="item.sendAccountId + item.createTime"
        >
          <div class="column" v-if="transType <= 2">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/liwu.png"
              alt=""
            />
            <span>礼品</span>
          </div>
          <div class="column" v-else-if="transType >= 7">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/yuedu.png"
              alt=""
            />
            <span>阅读</span>
          </div>
          <div class="column" v-else>
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/xuxie.png"
              alt=""
            />
            <span>续写</span>
          </div>
          <div class="column">
            <span>来自: </span>
            <el-avatar shape="square" size="medium" :src="item.sendHeadImage" />
            <span class="nickName">{{ item.sendUserName }}</span>
          </div>

          <div class="column">
            <span>{{ item.amount }}</span>
            <span>{{ item.walletType == 3 ? "ETH" : "DAIC" }}</span>
            <img
              v-if="item.walletType == 3"
              style="width: 20px; margin-left: 8px"
              src="@/assets/images/ethBig.png"
              alt=""
            />
            <img
              v-else
              style="width: 20px; margin-left: 8px"
              src="@/components/Header/images/btc.png"
              alt=""
            />
          </div>
          <div class="column">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/time.png"
              alt=""
            />
            <span>{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { articleIncome, articleIncomeListPage } from "@/api/money";
export default {
  name: "income",
  data() {
    return {
      list: [],
      obj: {
        contractAddress: "",
        nftId: "",
        redeemTime: "",
      },
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        needFlow: 2,
      },
      moneyObj: {},
      loading: false,
    };
  },
  mounted() {
    this.getData();
    this.getList();
  },
  methods: {
    getData() {
      // this.loading = true;
      let param = {
        ...this.queryParams,
        articleId: this.nft.articleId,
      };
      articleIncome(param).then(({ code, data }) => {
        if (code == 1000) {
          this.moneyObj = data;
          this.list = data.list;
          // this.loading = false;
        }
      });
    },
    // 交易类型 1=ETH赠送礼物 2= DAIC赠送礼物 3 = 开篇创作 4= 续写 5= 其它创作人平均分奖励  6=发布作品获得积分  7=支付ETH阅读权限 8=支付DAIC阅读权限
    getList() {
      this.loading = true;
      let param = {
        ...this.queryParams,
        articleId: this.nft.articleId,
      };
      articleIncomeListPage(param).then(({ code, data }) => {
        if (code == 1000) {
          this.list = data.list;
          this.loading = false;
        }
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      nft: (state) => state.page.nft,
    }),
  },
};
</script>

<style scoped lang="less">
.income {
  width: 1200px;
  margin: 20px auto;
  min-height: 80vh;
  .top {
    display: flex;
    .elImgs {
      box-sizing: border-box;
      width: 183px;
      height: 122px;
      border: 2px solid #999;
      margin-right: 12px;
    }
    .conte {
      .title {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 16px;
        margin-top: 30px;
      }
      .label {
        display: inline-block;
        width: 70px;
      }
      .item {
        width: 100%;
        line-height: 24px;
        font-size: 12px;

        .value {
          color: #828282;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 80px;
    margin: 20px 0;
    display: flex;
    color: #828282;
    .left {
      width: 230px;
      height: 80px;
      box-sizing: border-box;
      padding: 10px;
      margin-right: 20px;
      border-radius: 8px;
      background: #2d3034;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nums {
        .item {
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 10px 0;
          .money {
            color: #fff;
            margin-right: 6px;
          }
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      height: 80px;
      background: #2d3034;
      border-radius: 8px;
      .shouyi {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        .imgs {
          width: 62px;
          height: 48px;
          box-sizing: border-box;
          background: #15181d;
          display: flex;
          align-items: center;
          flex-flow: column;
          padding: 4px;
        }
        .nums {
          .item {
            display: flex;
            align-items: center;
            justify-content: end;
            margin: 10px 0;
            .money {
              color: #fff;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  .hader {
    line-height: 40px;
    font-size: 16px;
    color: #fff;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    .table {
      width: 100%;

      .table-column {
        margin: 10px 0;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 0 40px;
        background: #192a2c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .column {
          display: flex;
          align-items: center;
          span {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
